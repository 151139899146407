export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i;

export const SAFE_NAV_MENU_ITEMS = {
  safeTerms: 'SAFE Terms',
  companyInformation: 'Company Info',
  investorInformation: 'Investor Info',
  generatePdf: 'Generate PDF',
};

export const DISCOUNT_SAFE_TYPES = [
  'post_money_val_cap_discount',
  'post_money_discount',
  'pre_money_val_cap_discount',
  'pre_money_discount',
];

export const UNCAPPED_SAFE_TYPES = [
  'post_money_uncapped_mfn',
  'pre_money_uncapped_mfn',
];

export const PREMONEY_SAFE_TYPES = [
  'pre_money_val_cap',
  'pre_money_val_cap_discount',
  'pre_money_discount',
  'pre_money_uncapped_mfn',
];

export const SAFE_TYPE_OPTIONS = [
  {
    label: 'Standard YC Post Money SAFE with Valuation Cap',
    value: 'post_money_val_cap',
  },
  {
    label: 'Standard YC Post Money SAFE with Valuation Cap & Discount',
    value: 'post_money_val_cap_discount',
  },
  {
    label: 'Standard YC Post Money SAFE with Discount',
    value: 'post_money_discount',
  },
  {
    label: 'Standard YC Post Money SAFE (Uncapped + Most Favored Nation)',
    value: 'post_money_uncapped_mfn',
  },
  {
    label: 'Standard YC Pre Money SAFE with Valuation Cap',
    value: 'pre_money_val_cap',
  },
  {
    label: 'Standard YC Pre Money SAFE with Valuation Cap & Discount',
    value: 'pre_money_val_cap_discount',
  },
  {
    label: 'Standard YC Pre Money SAFE with Discount',
    value: 'pre_money_discount',
  },
  {
    label: 'Standard YC Pre Money SAFE (Uncapped + Most Favored Nation)',
    value: 'pre_money_uncapped_mfn',
  },
];

export const OUTPUT_OPTIONS = [
  {
    label: 'Generate a PDF',
    value: 'pdf',
  },
  {
    label: 'eSign on SAFE.new + PDF',
    value: 'e_sign',
  },
];

export const PRORATA_RIGHT_OPTIONS = [
  {
    label: 'No Pro Rata Rights',
    value: 'no_prorata',
  },
  {
    label: 'Include Pro Rata Rights',
    value: 'prorata',
  },
];

export const US_STATES = {
  DE: 'Delaware',
  CA: 'California',
  TX: 'Texas',
  NY: 'New York',
  FL: 'Florida',
  WA: 'Washington',
  IL: 'Illinois',
  PA: 'Pennsylvania',
  OH: 'Ohio',
  GA: 'Georgia',
  NC: 'North Carolina',
  MI: 'Michigan',
  NJ: 'New Jersey',
  VA: 'Virginia',
  AZ: 'Arizona',
  MA: 'Massachusetts',
  TN: 'Tennessee',
  IN: 'Indiana',
  MO: 'Missouri',
  MD: 'Maryland',
  WI: 'Wisconsin',
  CO: 'Colorado',
  MN: 'Minnesota',
  SC: 'South Carolina',
  AL: 'Alabama',
  LA: 'Louisiana',
  KY: 'Kentucky',
  OR: 'Oregon',
  OK: 'Oklahoma',
  CT: 'Connecticut',
  UT: 'Utah',
  IA: 'Iowa',
  NV: 'Nevada',
  AR: 'Arkansas',
  MS: 'Mississippi',
  KS: 'Kansas',
  NM: 'New Mexico',
  NE: 'Nebraska',
  WV: 'West Virginia',
  ID: 'Idaho',
  HI: 'Hawaii',
  NH: 'New Hampshire',
  ME: 'Maine',
  MT: 'Montana',
  RI: 'Rhode Island',
  SD: 'South Dakota',
  ND: 'North Dakota',
  AK: 'Alaska',
  DC: 'District of Columbia',
  VT: 'Vermont',
  WY: 'Wyoming',
  PR: 'Puerto Rico',
};

export const INVESTOR_TYPE_OPTIONS = [
  {
    label: 'Individual',
    value: 'individual',
  },
  {
    label: 'Venture Fund (LP)',
    value: 'venture_fund',
  },
  {
    label: 'Other Entity (Trust, LLC, Company)',
    value: 'entity',
  },
];

export const NAVIGATION_STEPS = {
  safeTerms: {
    next: 'companyInformation',
    prev: '',
  },
  companyInformation: {
    next: 'investorInformation',
    prev: 'safeTerms',
  },
  investorInformation: {
    next: 'generatePdf',
    prev: 'companyInformation',
  },
  generatePdf: {
    next: '',
    prev: 'investorInformation',
  },
};
